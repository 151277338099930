@import '../../../../../scss/_theme-bootstrap';

$trending-looks-small-breakpoint-down: max-width 767px;
$trending-looks-breakpoint: 768px;
$trending-looks-large-breakpoint: 1024px;
$trending-looks-extra-large-breakpoint: 1140px;
$trending-looks-full-view-breakpoint: 1280px;
$trending-looks-max-width: 1440px;
$trending-looks-product-bag-footer-height: 75px;
$trending-looks-desktop-column-title-offset: 15px;
$slick-prev-character: '\2190' !default;
$slick-next-character: '\2192' !default;

@mixin looksCarouselArrows($isWhite: false) {
  .slick-prev,
  .slick-next {
    font-size: 24px;
    position: static;
    display: inline-block;
    width: 45px;
    height: 45px;
    overflow: hidden;
    background-color: $color--black;
    background-position: center;
    &:before {
      content: '';
    }
    .dark-theme & {
      background-color: $color--white;
    }
  }
  .slick-prev {
    right: auto;
    left: auto;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-image: url('#{$base-theme-path}svg-icons/src/icon--arrow-left-lrg--black.svg');
    mask-image: url('#{$base-theme-path}svg-icons/src/icon--arrow-left-lrg--black.svg');
    .dark-theme & {
      -webkit-mask-image: url('#{$base-theme-path}svg-icons/src/icon--arrow-left-lrg--white.svg');
      mask-image: url('#{$base-theme-path}svg-icons/src/icon--arrow-left-lrg--white.svg');
    }
  }
  .slick-next {
    right: auto;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-image: url('#{$base-theme-path}svg-icons/src/icon--arrow-right-lrg--black.svg');
    mask-image: url('#{$base-theme-path}svg-icons/src/icon--arrow-right-lrg--black.svg');
    @include breakpoint($trending-looks-breakpoint) {
      margin-left: 8px;
    }
    .dark-theme & {
      -webkit-mask-image: url('#{$base-theme-path}svg-icons/src/icon--arrow-right-lrg--white.svg');
      mask-image: url('#{$base-theme-path}svg-icons/src/icon--arrow-right-lrg--white.svg');
    }
  }
}

html.trending-looks-item-overlay-opened {
  .trending-looks-block-formatter-background-wrapper--active {
    @include breakpoint($trending-looks-small-breakpoint-down) {
      overflow: hidden;
      height: 100%;
    }
    .trending-looks-block__content.slick-slider {
      > .slick-prev,
      > .slick-next {
        display: none;
      }
    }
  }
  .site-header-formatter--hidden.site-header-formatter--sticky .site-header-formatter__inner {
    @include breakpoint($trending-looks-small-breakpoint-down) {
      transform: none;
    }
  }
  .site-header-formatter__inner {
    @include breakpoint($trending-looks-small-breakpoint-down) {
      z-index: 2001;
    }
  }
}

html.trending-looks-item-overlay-animated {
  .trending-looks-block-formatter-background-wrapper--active {
    .trending-looks-block-formatter {
      @include breakpoint($trending-looks-breakpoint) {
        min-height: 80vh;
        margin: auto;
      }
    }
  }
}

.trending-looks-block-formatter-background-wrapper {
  @include breakpoint($trending-looks-breakpoint) {
    position: relative;
    overflow: hidden;
  }
  &.multi-looks,
  &.single-look {
    min-height: 500px;
    @include breakpoint($trending-looks-breakpoint) {
      min-height: 700px;
    }
  }
}

.trending-looks-block-formatter-background {
  display: none;
  position: absolute;
  background: rgba($color--black, 0.45);
  z-index: 1;
  top: 0;
  #{$ldirection}: 0;
  width: 100%;
  height: 100%;
  transition: all 1s;
  .multi-looks &,
  .single-look & {
    background: rgba($color--black, 0.8);
  }
  .trending-looks-item-overlay-opened .trending-looks-block-formatter-background-wrapper--active & {
    display: block;
    opacity: 0;
  }
  .trending-looks-item-overlay-animated
    .trending-looks-block-formatter-background-wrapper--active
    & {
    opacity: 1;
  }
  .trending-looks-item-overlay-animated-closing & {
    transition: opacity 0.2s;
    opacity: 0;
  }
}
.trending-looks-block-formatter {
  .multi-looks &,
  .single-look & {
    max-width: 1440px;
    min-height: 0;
    height: 100%;
    justify-content: center;
    @include breakpoint($trending-looks-breakpoint) {
      margin: 30px auto;
    }
  }
  @include breakpoint($trending-looks-breakpoint) {
    display: flex;
    align-items: center;
    height: 60vh;
    min-height: 640px;
    margin: 60px auto;
    max-width: $trending-looks-max-width;
    position: relative;
  }
  .product-brief-v2 {
    .product-brief__image-link {
      padding-top: 40px;
    }
    .product-brief__cta
      .product-brief__inventory-status
      .product-inventory-status__list
      .product-inventory-status__temp-oos-text {
      min-width: 0;
    }
    .product-brief__container {
      border: none;
    }
    .product__rating-stars .product__rating-percentage {
      z-index: 2;
    }
    .product-brief__image-img--alt-image {
      display: none;
    }
    .product-brief__description {
      display: none;
    }
    .product-brief__shades {
      margin-top: 0;
    }
    .slick-prev,
    .slick-next {
      z-index: 2;
    }
    .product-brief__details {
      min-height: 0;
    }
    .product-brief__price,
    .product-price-v1 {
      width: 100%;
      float: none;
      text-align: center;
      margin: auto;

      .product-price {
        font-weight: normal;
      }
    }
    .product-brief__footer {
      @include breakpoint($trending-looks-breakpoint) {
        min-height: 0;
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
  }
  .slick-slider {
    margin-bottom: 0;
  }
  .slick-dots {
    position: static;
    #{$ldirection}: 0;
    #{$rdirection}: 0;
    top: 0;
    bottom: 0;
    color: inherit;
    > li {
      display: inline-block;
      margin: 0;
      color: inherit;
      &:before {
        color: inherit;
        position: static;
      }
      > button {
        color: inherit !important;
        padding: 0;
      }
    }
    > li,
    > li > button,
    > li > button:before {
      position: static;
      width: 10px;
      height: 10px;
      color: inherit !important;
    }
  }
}

.trending-looks-block {
  &__wrapper {
    @include breakpoint($trending-looks-breakpoint) {
      display: flex;
      overflow: hidden;
    }
    .multi-looks &,
    .single-look & {
      flex-direction: column;
    }
  }
  &__intro {
    padding: 34px 20px 10px;
    @include breakpoint($trending-looks-breakpoint) {
      padding-top: 20px;
      flex: 0 0 400px;
    }
    @include breakpoint($trending-looks-breakpoint) {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .multi-looks & {
      padding: 32px 20px 20px;
      @include breakpoint($trending-looks-breakpoint) {
        @include swap_direction(padding, 20px 0 0 20px);
        margin-bottom: -40px;
      }
    }
    &-eyebrow {
      font-family: $headline-ano-bold-font;
      font-size: 14px;
      .single-look &,
      .multi-looks & {
        margin-bottom: 20px;
        padding-#{$ldirection}: 5px;
      }
    }
    &-title {
      font-family: $ano_regular-font;
      font-size: 40px;
      @include breakpoint($trending-looks-breakpoint) {
        font-size: 54px;
      }
    }
    &-subtitle {
      @include body-text;
    }
    &-arrows,
    &-slick-arrows {
      @include looksCarouselArrows;
      display: none;
      margin: 30px 0 60px;
      @include breakpoint($trending-looks-breakpoint) {
        display: block;
        color: $color--black;
        position: relative;
      }
      .single-look & {
        margin: 0;
      }
      .slick-disabled {
        opacity: 0.2;
      }
    }
    &-footer {
      display: none;
      @include breakpoint($trending-looks-breakpoint) {
        display: block;
        position: absolute;
        bottom: 50px;
      }
    }
    .trending-looks-block__intro-footer {
      color: inherit;
      a {
        color: inherit;
        border-bottom-color: inherit;
      }
    }
    .multi-looks & {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      flex: 0 1 auto;
      .trending-looks-block__intro-eyebrow {
        flex: 0 0 100%;
      }
    }
    .single-look & {
      @include breakpoint($trending-looks-breakpoint) {
        flex: 0 0 100%;
        position: absolute;
        padding-top: 0;
      }
    }
    .split-left & {
      #{$rdirection}: 0;
      @include breakpoint($trending-looks-breakpoint) {
        padding-#{$ldirection}: 5px;
        width: 50%;
      }
    }
    .split-left &,
    .split-right & {
      @include breakpoint($trending-looks-breakpoint) {
        width: 50%;
      }
    }
  }
  &__content {
    color: inherit;
    .multi-looks & {
      padding-bottom: 0;
      padding-top: 0;
      margin-#{$ldirection}: 10px;
    }
    @include breakpoint($trending-looks-breakpoint) {
      flex: 0 0 100%;
      display: flex;
      align-items: center;
      color: inherit;
      &.rotated-left,
      &.rotated-right {
        .trending-looks-item-article-trigger__title {
          opacity: 0;
        }
        .trending-looks-item-article-trigger__footer {
          opacity: 0;
        }
        .display-title {
          .trending-looks-item-article-trigger__title {
            transition: opacity 0.3s 1s;
            opacity: 1;
          }
          .trending-looks-item-article-trigger__footer {
            transition: opacity 0.3s 1s;
            opacity: 1;
          }
        }
      }
      > * {
        &:nth-child(1) {
          flex: 0 0 38%;
        }
        &:nth-child(2),
        &:nth-child(3) {
          flex: 0 0 22%;
        }
        &:not(:first-child) {
          .trending-looks-item-article-trigger__title {
            @include breakpoint($trending-looks-breakpoint) {
              visibility: hidden;
            }
          }
          .trending-looks-item-article-trigger__footer {
            @include breakpoint($trending-looks-breakpoint) {
              display: none;
            }
          }
        }
        .multi-looks & {
          flex: 0 1 auto;
        }
      }
    }
    &-article {
      color: inherit;
      @include breakpoint($trending-looks-breakpoint) {
        position: relative;
        overflow: hidden;
      }
      > *:not(:first-child) {
        @include breakpoint($trending-looks-breakpoint) {
          top: 0;
          position: absolute;
          width: 100%;
          height: 100%;
          transition: all 1s ease-in-out;
        }
        &.initialized {
          .rotated-left & {
            @include breakpoint($trending-looks-breakpoint) {
              transform: translateX(-115%);
              -webkit-backface-visibility: hidden;
            }
          }
          .rotated-right & {
            @include breakpoint($trending-looks-breakpoint) {
              transform: translateX(115%);
              -webkit-backface-visibility: hidden;
            }
          }
          @if $text-direction == 'rtl' {
            .rotated-left & {
              @include breakpoint($trending-looks-breakpoint) {
                transform: translateX(115%);
              }
            }
            .rotated-right & {
              @include breakpoint($trending-looks-breakpoint) {
                transform: translateX(-115%);
              }
            }
          }
        }
        &.transition {
          .rotated-right &,
          .rotated-left & {
            @include breakpoint($trending-looks-breakpoint) {
              transform: translateX(0);
            }
          }
        }
      }
      .trending-looks-item-article-trigger {
        color: inherit;
        .trending-looks-item-article-trigger__grid {
          color: inherit;
          .trending-looks-item-article-trigger__title {
            color: inherit;
            .trending-looks-item-article-trigger__title-inner {
              color: inherit;
            }
          }
        }
      }
    }
    .slick-list {
      .multi-looks & {
        overflow-x: clip;
        overflow-y: visible;
        margin-#{$ldirection}: 10px;
      }
    }
    &.stacked,
    &.split-right,
    &.split-left {
      .trending-looks-block__content-article {
        flex: 0 1 auto;
        overflow: initial;
        margin-right: 10px;
      }
    }
    .multi-looks &,
    .single-look & {
      .trending-looks-item-article-trigger__item-title,
      .trending-looks-item-article-trigger__item-title-2,
      .trending-looks-item-article-trigger__item-description {
        text-align: start;
      }
      .trending-looks-item-article-trigger__item-title {
        @include title-03;
        margin-bottom: 15px;
        writing-mode: unset;
        transform: none;
      }
      .trending-looks-item-article-trigger__item-description {
        @include body-text;
      }
      .trending-looks-item-article-trigger__item-info {
        margin-top: 18px;
      }
    }
    .trending-looks-item-article-trigger__item-info {
      .multi-looks & {
        min-height: 120px;
        order: 2;
        width: 95%;
        @include breakpoint($trending-looks-breakpoint) {
          width: 100%;
        }
      }
      .single-look & {
        order: 2;
        @include breakpoint($trending-looks-breakpoint) {
          order: 0;
        }
      }
      .multi-looks &,
      .single-look & {
        margin-top: 24px;
        @include breakpoint($trending-looks-breakpoint) {
          margin-top: 18px;
        }
      }
    }
    &.split-right,
    &.split-left {
      .trending-looks-item-article-trigger__item-info {
        display: flex;
        flex-direction: column;
        justify-content: end;
        @include breakpoint($trending-looks-breakpoint) {
          width: 50%;
        }
      }
    }
    &.split-left {
      .trending-looks-item-article-trigger__item-info {
        order: 3;
        @include breakpoint($trending-looks-breakpoint) {
          padding-#{$ldirection}: 20px;
        }
      }
    }
  }
  &-formatter-background-wrapper:not(.multi-looks) {
    .slick-list {
      padding: 0 20px;
    }
  }
  &__mobile-footer {
    display: flex;
    padding: 20px;
    .multi-looks & {
      color: inherit;
    }
    .trending-looks-dots {
      .multi-looks & {
        margin: 0 auto;
        color: inherit;
        .slick-dots {
          > li {
            margin-#{$rdirection}: 5px;
            > button {
              &:before {
                opacity: 1;
                position: static;
                border: 1px solid;
                border-radius: 10px;
                background-color: transparent;
                .dark-theme & {
                  border-color: $color--white;
                  color: transparent !important;
                }
                .light-theme & {
                  border-color: $color--black;
                  color: transparent !important;
                }
              }
            }
          }
          .slick-active > button {
            &:before {
              opacity: 1;
              color: inherit;
              border: 1px solid;
              border-radius: 10px;
              .dark-theme & {
                background-color: $color--white;
              }
              .light-theme & {
                background-color: $color--black;
              }
            }
          }
        }
      }
    }
    > :first-child {
      flex: 0 0 auto;
      margin-#{$rdirection}: auto;
    }
    > :last-child {
      flex: 0 1 auto;
    }
    @include breakpoint($trending-looks-breakpoint) {
      display: none;
    }
  }
}

.trending-looks-block-formatter.no-carousel {
  .trending-looks-block__content {
    padding: 0 20px 20px;
    &-article {
      position: relative;
    }
    .single-look & {
      padding: 0 20px 50px;
    }
  }
  .slick-next,
  .slick-prev {
    display: none;
  }
  .trending-looks-block__mobile-footer {
    display: none;
  }
}

.trending-looks-block-item-overlay-stage {
  position: fixed;
  top: 0;
  #{$ldirection}: 0;
  width: 100%;
  height: 100%;
  z-index: 1001;
  overflow-y: scroll;
  background: $color--white;
  @include breakpoint($trending-looks-small-breakpoint-down) {
    transform: translateY(100%);
    opacity: 0;
  }
  .trending-looks-item-overlay-opened .trending-looks-block-formatter-background-wrapper--active & {
    @include breakpoint($trending-looks-small-breakpoint-down) {
      transition: opacity 0.3s, transform 0.5s;
      padding-top: 92px; // magic number for site header alignment
    }
  }
  .trending-looks-item-overlay-animated
    .trending-looks-block-formatter-background-wrapper--active
    & {
    @include breakpoint($trending-looks-small-breakpoint-down) {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .trending-looks-item-overlay-animated-completed & {
    @include breakpoint($trending-looks-small-breakpoint-down) {
      transform: none;
    }
  }

  @include breakpoint($trending-looks-breakpoint) {
    z-index: 5;
    position: absolute;
    overflow-y: scroll;
    background: transparent;
  }
  &__content {
    @include breakpoint($trending-looks-small-breakpoint-down) {
      position: relative;
      height: 100%;
    }
  }
  &__close {
    position: absolute;
    top: 10px;
    #{$rdirection}: 20px;
    background: transparent;
    appearance: none;
    border: none;
    z-index: 1001;
    @include breakpoint($trending-looks-small-breakpoint-down) {
      display: none;
      &--mobile {
        display: block;
      }
    }
    @include breakpoint($trending-looks-breakpoint) {
      top: 15px;
      &--mobile {
        display: none;
      }
    }
    svg {
      fill: $color--white;
      width: 20px;
      height: 20px;
      @include breakpoint($trending-looks-breakpoint) {
        fill: $color--black;
      }
    }
  }
  &__content {
    @include breakpoint($trending-looks-breakpoint) {
      transition: transform 0.4s;
      background: $color--white;
      margin-left: auto;
      height: 100%;
      max-width: 100%;
      transform: translateX(100%);
    }
    @include breakpoint($trending-looks-full-view-breakpoint) {
      max-width: 75%;
    }
    .trending-looks-item-overlay-animated
      .trending-looks-block-formatter-background-wrapper--active
      & {
      @include breakpoint($trending-looks-breakpoint) {
        transform: translateX(0);
      }
    }
    &-target {
      height: 100%;
    }
  }
}

.trending-looks-block-item-overlay {
  height: 100%;
  overflow-y: scroll;
  @include breakpoint($trending-looks-breakpoint) {
    display: flex;
  }
  &__info {
    position: relative;
    background: $color--black;
    color: $color--white;
    padding: 40px 20px 60px;
    @include breakpoint($trending-looks-breakpoint) {
      width: 40%;
      padding-top: 0;
    }
    @include breakpoint($trending-looks-large-breakpoint) {
      width: 50%;
    }
  }
  &__content {
    position: relative;
    @include breakpoint($trending-looks-breakpoint) {
      width: 60%;
      overflow-y: scroll;
    }
    @include breakpoint($trending-looks-large-breakpoint) {
      width: 50%;
    }
    &-title {
      font-size: 28px;
      font-family: $ano_regular-font;
      margin: 40px 20px 15px;
      @include breakpoint($trending-looks-breakpoint) {
        font-size: 32px;
        border-bottom: 1px solid $color--black;
      }
    }
    &-rendered {
      padding: 0 20px $trending-looks-product-bag-footer-height;
    }
    &-footer {
      #{$ldirection}: 0;
      width: 100%;
      position: fixed;
      bottom: 0;
      z-index: 1000;
      @include breakpoint($trending-looks-breakpoint) {
        position: sticky;
        z-index: 10;
        top: calc(
          100% - #{$trending-looks-product-bag-footer-height}
        ); // magic number - 100% minus height of footer container to make sticky position properly
        height: 0;
        bottom: auto;
      }
      &-wrapper {
        display: flex;
        width: 100%;
        background: $color--white;
        border-top: 1px solid $color-cta-grey;
        align-items: center;
        justify-content: space-between;
        height: $trending-looks-product-bag-footer-height;
        padding: 0 20px;
        @include breakpoint($trending-looks-breakpoint) {
          padding: 0 32px;
        }
      }
      &-label {
        font-family: $headline-ano-bold-font;
        font-size: 14px;
      }
      &-label-footnote {
        display: block;
        color: $color--gray;
        font-size: 12px;
        font-family: $ano_regular-font;
        padding-#{$rdirection}: 20px;
      }
      &-button {
        .button {
          font-family: $headline-ano-bold-font;
          color: $color--white !important;
          font-size: 12px;
          height: 45px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 20px;
          line-height: 1;
          border-radius: 100px;
        }
      }
    }
  }
}

.trending-looks-item-article-trigger {
  display: block;
  width: 100%;
  height: 100%;
  appearance: none;
  border: none;
  background: transparent;
  &__grid {
    @include breakpoint($trending-looks-breakpoint) {
      display: flex;
      position: relative;
    }
    .multi-looks &,
    .single-look & {
      display: flex;
      flex-direction: column;
      cursor: auto;
    }
    .stacked & {
      @include breakpoint($trending-looks-breakpoint) {
        flex-direction: column-reverse;
      }
    }
    .split-right &,
    .split-left & {
      @include breakpoint($trending-looks-breakpoint) {
        flex-direction: row;
      }
    }
  }
  &__image,
  &__video {
    align-self: flex-start;
    width: 100%;
    .multi-looks &,
    .single-look & {
      order: 1;
      @include breakpoint($trending-looks-breakpoint) {
        order: 2;
      }
    }
    .split-right &,
    .split-left & {
      @include breakpoint($trending-looks-breakpoint) {
        width: 50%;
      }
    }
  }
  &__video {
    position: relative;
  }
  &__title {
    font-size: 12px;
    font-family: $headline-ano-bold-font;
    padding: 0px 0 2px;
    width: 100%;
    text-align: #{$ldirection};
    margin-bottom: 10px;
    @include breakpoint($trending-looks-breakpoint) {
      padding-top: 15px;
      margin-bottom: 0;
      writing-mode: vertical-rl;
      transform: rotate(-180deg);
      align-self: flex-start;
      margin-#{$rdirection}: $trending-looks-desktop-column-title-offset;
      flex: 0 0 1rem;
    }
  }
  &__footer {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 16px;
    width: 100%;
    justify-content: space-between;
    padding: 0 20px;
    @include breakpoint($trending-looks-breakpoint) {
      padding-#{$ldirection}: calc(
        2rem + #{$trending-looks-desktop-column-title-offset}
      ); // magic alignment
      padding-#{$rdirection}: 20px;
    }
    .multi-looks &,
    .single-look & {
      order: 3;
      padding: 0;
      bottom: -50px;
      position: static;
      @include breakpoint($trending-looks-breakpoint) {
        order: 0;
      }
    }
    .single-look & {
      bottom: -20px;
      padding-#{$ldirection}: 0;
      @include breakpoint($trending-looks-breakpoint) {
        position: absolute;
      }
    }
    .split-left & {
      @include breakpoint($trending-looks-breakpoint) {
        width: 50%;
        #{$rdirection}: 0;
        bottom: -20px;
      }
    }
    &-products {
      flex: 0 0 100%;
      display: flex;
      width: 100%;
      justify-content: center;
      @include breakpoint($trending-looks-breakpoint) {
        justify-content: flex-start;
      }
      .trending-looks-item-overlay-article-trigger-product {
        display: block;
        background: $color--white;
        width: 84px;
        margin-#{$rdirection}: 4px;
        img {
          display: block;
        }
      }
      @include breakpoint($trending-looks-breakpoint) {
        flex: 0 0 50%;
        max-width: 50%;
      }
      @include breakpoint($trending-looks-full-view-breakpoint) {
        max-width: unset;
      }
      .multi-looks &,
      .single-look & {
        display: none;
      }
    }
    &-cta {
      display: none;
      .multi-looks &,
      .single-look & {
        display: block;
      }
      .split-left & {
        @include breakpoint($trending-looks-breakpoint) {
          position: absolute;
          #{$ldirection}: 20px;
          top: -55px;
        }
      }
      @include breakpoint($trending-looks-breakpoint) {
        display: block;
        flex: 0 1;
      }
      .button {
        font-family: $headline-ano-bold-font;
        background: $color--white;
        color: $color--black;
        border-radius: 100px;
        transition: all 0.2s;
        padding: 0 20px;
        &:hover {
          background: $color--black;
          color: $color--white;
        }
        .multi-looks &,
        .single-look & {
          background: transparent;
          color: inherit;
          padding: 0;
          border-radius: unset;
          border-bottom: 1px solid $color--white--opacity-3;
          height: 35px;
          @include breakpoint($trending-looks-breakpoint) {
            border: 1px solid $color--black;
            background-color: $color--black;
            color: $color--white;
            border-radius: 50px;
            padding: 0 24px;
            font-size: 12px;
            line-height: 35px;
          }
          &:hover {
            border-bottom: 1px solid $color--white;
            @include breakpoint($trending-looks-breakpoint) {
              border: 1px solid $color--black;
            }
          }
          .dark-theme & {
            @include breakpoint($trending-looks-breakpoint) {
              border-color: $color-cta-grey;
              background-color: $color--white;
              color: $color--black;
            }
          }
        }
      }
    }
  }
}

.trending-looks-block-item-info {
  &__full {
    height: 100%;
    overflow-y: auto;
    padding-bottom: 10px;
    @include breakpoint($trending-looks-breakpoint) {
      margin-top: 20px;
      height: calc(100% - 20px);
    }

    > img {
      display: block;
      margin: auto;
      width: 100%;
    }
    &-text {
      margin-top: 20px;
    }
    &-title {
      font-family: $headline-ano-bold-font;
      font-size: 12px;
    }
    &-description {
      margin-top: 16px;
      font-family: $ano_regular-font;
      font-size: 16px;
    }
  }
  &__arrows {
    @include looksCarouselArrows(true);
    display: flex;
    position: absolute;
    width: 100%;
    bottom: 40px;
    #{$ldirection}: 0;
    padding: 0 20px;
    @include breakpoint($trending-looks-breakpoint) {
      bottom: 40px;
    }
    .slick-prev,
    .slick-next {
      background-color: $color--white;
      width: 34px;
      @include breakpoint($trending-looks-breakpoint) {
        width: 45px;
      }
      &:before,
      &:hover:before,
      &:active:before,
      &:focus:before {
        color: $color--white;
      }
      .multi-looks & {
        top: -50px;
      }
    }
    .slick-next {
      margin-#{$ldirection}: auto;
    }
    .multi-looks &,
    .single-look & {
      display: none;
    }
  }
}

.trending-looks-block-item-step {
  display: flex;
  flex-direction: column;
  @include breakpoint($trending-looks-breakpoint) {
    display: grid;
    grid-template-columns: 50% 50%;
  }
  &__product {
    .product-brief__cta {
      .product-brief__add-to-bag {
        position: relative;
      }
    }
    @include breakpoint($trending-looks-small-breakpoint-down) {
      order: 2;
    }
  }
  &__text {
    font-family: $ano_regular-font;
    display: flex;
    justify-content: center;
    flex-direction: column;
    @include breakpoint($trending-looks-breakpoint) {
      padding-#{$ldirection}: 35px;
      justify-content: flex-start;
      padding-top: 100px;
    }
    &-number {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid $color--black;
      border-radius: 50%;
      font-size: 12px;
      width: 30px;
      height: 30px;
      margin-bottom: 10px;
      @include breakpoint($trending-looks-breakpoint) {
        width: 54px;
        height: 54px;
        font-size: 22px;
        margin-bottom: 20px;
      }
    }
  }
}
.trending-looks-block-item-products-grid {
  @include breakpoint($trending-looks-breakpoint) {
    display: flex;
  }
}

[dir=rtl] {
  .trending-looks-block {
    &__content {
      .slick-prev {
        &:before {
          content: $slick-prev-character;
        }
      }

      .slick-next {
        &:before {
          content: $slick-next-character;
        }
      }
    }
  }
}
